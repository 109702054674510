
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'

@Component({
  components: {
    ExpandIcon,
  },
})
export default class PopupBox extends Vue {
  @Prop() private buttonText!: string
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: false }) private showAppliedFilterAmount!: boolean
  @Prop({ default: 0 }) private appliedAmount!: number

  private hasSlot(slotName: string): boolean {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }
}
